/* eslint-disable no-warning-comments */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState, useEffect }  from 'react';
import _ from 'lodash';
import footerLogo from 'wmic-amp-digital-theme-styles/images/Wawanesa_Insurance_grey.svg';
import bestLogo from 'wmic-amp-digital-theme-styles/images/AMBest-Ap-Rating.png';
import { WMICLink } from 'wmic-components-amp-common-react';
import { WMICPageNavigationUtil, WMICRichTextUtil, WMICFeatureFlagUtil, CONSTANTS, LOBConstants, APP_NAMES } from 'wmic-portals-utils-js';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import appConfig from 'app-config';
import messages from './WMICDefaultPageFooter.messages';

function WMICDefaultPageFooter(props) {
    const WAWANESA_DOMAIN = CONSTANTS.WAWANESA_URL;
    const JURISDICTIONS = CONSTANTS.JURISDICTIONS;
    const ampString = APP_NAMES.AMP;
    const currentYear = new Date().getFullYear();
    const { userInfo: authUserData } = useAuthentication();
    const [fFlags, setfFlags] = useState({});
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const { footerUrls } = appConfig;
    const translator = useTranslator();

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setfFlags(rFlags);
    }

    const aceFnolTakeoverAvailable = useCallback(() => {
        return WMICFeatureFlagUtil.queryScheduledFeatureFlagAMP(fFlags, featureFlags.ACEFNOLTAKEOVER, new Date());
    },[fFlags, featureFlags]);

    useEffect (() => {
        initFeatureFlags();
    }, []);

    const getFnolLinks = useCallback((stateParam, lob) => { //feature flag controls entry of this function call, thus precisely control which <li> get rendered.
        if (stateParam === CONSTANTS.STATE.CA_LONG && lob === LOBConstants.PA) {
            return (
                <li>
                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.submitAnAutoClaimTrackButtonIdentifier, {region: translator(messages.california)})} href={`${location.origin}/${ampString}/california/auto-claim`}>{translator(messages.submitAnAutoClaim)}</WMICLink>
                </li>
            );
        } else if (stateParam === CONSTANTS.STATE.CA_LONG && lob === LOBConstants.HOME) {
            return (                
                <li>
                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.submitAResidentialClaimTrackButtonIdentifier, {region: translator(messages.california)})} href={`${location.origin}/${ampString}/california/residential-claim`}>{translator(messages.submitAResidentialClaim)}</WMICLink>
                </li>
            );
        } else if (stateParam === CONSTANTS.STATE.OR_LONG && lob === LOBConstants.PA) {
            return (
                <li>
                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.submitAnAutoClaimTrackButtonIdentifier, {region: translator(messages.oregon)})} href={`${location.origin}/${ampString}/oregon/auto-claim`}>{translator(messages.submitAnAutoClaim)}</WMICLink>
                </li>
            );
        } else if (stateParam === CONSTANTS.STATE.OR_LONG && lob === LOBConstants.HOME) {
            return (  
                <li>
                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.submitAResidentialClaimTrackButtonIdentifier, {region: translator(messages.oregon)})} href={`${location.origin}/${ampString}/oregon/residential-claim`}>{translator(messages.submitAResidentialClaim)}</WMICLink>
                </li>
            );
        } else {
            return (
                <li>
                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.fileAClaimOnlineTrackButtonIdentifier, {region: translator(messages.california)})} href={`${footerUrls.claimOnline}`}>{translator(messages.fileAClaimOnline)}</WMICLink>
                </li>
            )
        } 
    });

    const { addPolicyModal, preferredAutoShopsAvailable, isPublicURLWithFooter, navigateToContactUs, isAuthenticated } = props;
    // eslint-disable-next-line max-len
    return (isAuthenticated || isPublicURLWithFooter) ? (
        <footer className="no-print">
            <div className="ww-footer-top">
                <div className="ww-content-limit">
                    <div>
                        <div className="ww-footer-col">
                            <h4>{translator(messages.aboutWawanesa)}</h4>
                            <ul>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.privacyPolicyTrackButtonIdentifier)} href={`${WAWANESA_DOMAIN}/home/amp/privacy-policy.html`}>{translator(messages.privacyPolicy)}</WMICLink>
                                </li>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.privacyNoticeTrackButtonIdentifier)} href={`${WAWANESA_DOMAIN}/us/legal/privacy-notice.html`}>{translator(messages.privacyNotice)}</WMICLink>
                                </li>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.termsOfUseTrackButtonIdentifier)} href={`${WAWANESA_DOMAIN}/home/amp/terms-of-use.html`}>{translator(messages.termsOfUse)}</WMICLink>
                                </li>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.onlinePaymentTermsTrackButtonIdentifier)} href={`${WAWANESA_DOMAIN}/home/amp/online-payment-terms.html`}>{translator(messages.onlinePaymentTerms)}</WMICLink>
                                </li>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.accessibilityTrackButtonIdentifier)} href={`${WAWANESA_DOMAIN}/us/about-us/accessibility.html`}>{translator(messages.accessibility)}</WMICLink>
                                </li>
                            </ul>
                        </div>
                        <div className="ww-footer-col">
                            <h4>{translator(messages.californiaCustomers)}</h4>
                            <ul>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.requestAnAutoQuoteTrackButtonIdentifier, {region: translator(messages.california)})} href={`${footerUrls.autoQuoteCA}`}>{translator(messages.requestAnAutoQuote)}</WMICLink>
                                </li>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.requestACondominiumQuoteTrackButtonIdentifier, {region: translator(messages.california)})} href={`${footerUrls.condoQuoteCA}`}>{translator(messages.requestACondominiumQuote)}</WMICLink>
                                </li>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.requestARentersQuoteTrackButtonIdentifier, {region: translator(messages.california)})} href={`${footerUrls.renters}?region=${JURISDICTIONS.CA}`}>{translator(messages.requestARentersQuote)}</WMICLink>
                                </li>
                                <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.requestAHomeownersQuoteTrackButtonIdentifier, {region: translator(messages.california)})} href={`${footerUrls.homeOwnersQuoteCA}`}>{translator(messages.requestAHomeownersQuote)}</WMICLink>
                                </li>
                                {!aceFnolTakeoverAvailable() && getFnolLinks(CONSTANTS.STATE.CA_LONG, LOBConstants.PA)}
                                {!aceFnolTakeoverAvailable() && getFnolLinks(CONSTANTS.STATE.CA_LONG, LOBConstants.HOME)}
                                {aceFnolTakeoverAvailable() && getFnolLinks()}
                                {preferredAutoShopsAvailable() && <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.viewPreferredAutoshopsTrackButtonIdentifier, {region: translator(messages.california)})} href={`${location.origin}/${ampString}/preferred-autoshops`}>{translator(messages.viewPreferredAutoshops)}</WMICLink>
                                </li>}
                            </ul>
                        </div>
                        <div className="ww-footer-col">
                            <h4>{translator(messages.oregonCustomers)}</h4>
                            <ul>
                                {!aceFnolTakeoverAvailable() && getFnolLinks(CONSTANTS.STATE.OR_LONG, LOBConstants.PA)}
                                {!aceFnolTakeoverAvailable() && getFnolLinks(CONSTANTS.STATE.OR_LONG, LOBConstants.HOME)}
                                {aceFnolTakeoverAvailable() && getFnolLinks()}
                                {preferredAutoShopsAvailable() && <li>
                                    <WMICLink aria-live="off" target="_blank" trackButtonIdentifier={translator(messages.viewPreferredAutoshopsTrackButtonIdentifier, {region: translator(messages.oregon)})} href={`${location.origin}/${ampString}/preferred-autoshops`}>{translator(messages.viewPreferredAutoshops)}</WMICLink>
                                </li>}
                            </ul>
                        </div>
                        <div className="ww-footer-col ww-footer-logo">
                            <img src={footerLogo} alt="Wawanesa Insurance" className="ww-portal-logo-footer" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ww-footer-divider">
                <div className="ww-backtop-link">
                    <WMICLink aria-live="off" onClick={(e) => WMICPageNavigationUtil.scrollToIndex(e, 'root')} to="/">
                        <i className="fa fa-fw fa-angle-up" />
                    </WMICLink>
                </div>
            </div>
            <div className="ww-footer-bottom">
                <div className="ww-content-limit">
                    <div className="ww-footer-bottom-left">
                        <p>
                            {translator(messages.copyright, { year: currentYear })}
                        </p>
                        <p className="ww-subscript">{translator(messages.wawanesaSubscript)}</p>
                    </div>
                    <div className="ww-footer-bottom-right">
                        <WMICLink aria-live="off" href={`${WAWANESA_DOMAIN}/us`} target="_blank">{translator(messages.wawanesaComUs)}</WMICLink>
                        &nbsp;|&nbsp;
                        <WMICLink aria-live="off" href="/contactUs" onClick={navigateToContactUs}>{translator(messages.contactUs)}</WMICLink>
                        &nbsp;|&nbsp;
                        <WMICLink aria-live="off" href={`${WAWANESA_DOMAIN}/us/california/news-listing.html`} target="_blank">{translator(messages.newsBoard)}</WMICLink>
                        <br />
                        <div className="ww-social-icons">
                            <div className="facebook">
                                <WMICLink aria-live="off" href="https://www.facebook.com/wawanesaus/" target="_blank" title="Wawanesa on Facebook"><i className="fa icon-proportions fa-facebook-square" /></WMICLink>
                            </div>
                            <div className="instagram">
                                <WMICLink aria-live="off" href="https://www.instagram.com/wawanesaus/" target="_blank" title="Wawanesa on Instagram"><i className="fa icon-proportions fa-instagram" /></WMICLink>
                            </div>
                            <div className="twitter">
                                <WMICLink aria-live="off" href="https://twitter.com/wawanesaus" target="_blank" title="Wawanesa on Twitter"><i className="fa icon-proportions fa-twitter-square" /></WMICLink>
                            </div>
                        </div>
                        <div className="bestLogo">
                            <WMICLink aria-live="off" href={`${WAWANESA_DOMAIN}/us/about-us/company-information.html#financialRatings`} target="_blank">
                                <img id="logoAMBest" alt={translator(messages.amBestImgAltText)} src={bestLogo} />
                            </WMICLink>
                        </div>
                        <p className="ww-subscript ng-binding">
                            {WMICRichTextUtil.translateRichText(translator(messages.recaptchaPolicy))}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    ) : null;
}

export default WMICDefaultPageFooter;
