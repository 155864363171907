/* eslint-disable max-len */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { Icon, InfoLabel, Tooltip, DateRangeField, useModal } from '@jutro/components';
import { useCookies } from 'react-cookie';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { PolicyService } from 'gw-capability-policy';
import { ClaimService } from 'gw-capability-claim';
import { Decimal } from 'decimal.js-light';
import { AMPSubmissionDraftService } from 'gw-capability-quoteandbind';
import { WMICModal } from 'gw-capability-policy-react';
import { DocumentService } from 'gw-capability-policydocument';
import {
    WMICLoader,
    Currency as CurrencyField
} from 'gw-components-platform-react';
import {
    WMICClaimSummaryBoxAMP,
    WMICPolicySummaryBoxAMP,
    WMICLandingPagePaymentStatusModal,
    WMICOverduePolicies,
    WMICPaymentModal,
    WMICAddPolicyModal,
    WMICLink,
    WMICEditContactModal,
    WMICRcn
} from 'wmic-components-amp-common-react';
import {
    WMICNoticesComponent
} from 'gw-capability-policy-common-react';
import { useMediaQuery } from 'react-responsive';
import { getNormalizedLOBName, isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { useAuthentication } from 'wmic-digital-auth-react';
import {
    LobIconUtil,
    LocalDateUtil,
    WMICTooltipCookieUtil,
    WMICFeatureFlagUtil,
    CONSTANTS,
    WMICPaymentUtil,
    WMICBillingUtil,
    WMICRichTextUtil,
    WMICAmpUtil, WMICRemoteLogger
} from 'wmic-portals-utils-js';
import { generateURL } from 'wmic-portals-url-js';
import { useTranslator } from '@jutro/locale';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
// import getClaimSummariesMockData from '../../overrides/mockdata/ClaimService/getClaimSummaries.json';
import { WMICReviewContactInfo } from 'wmic-components-capability-common-react';
import metadata from './LandingPage.metadata.json5';
import messages from './LandingPage.messages';
import styles from './LandingPage.module.scss';
import { publish } from "@jutro/events";

import { Button } from '@jutro/legacy/components';

const POLICY_STATUS = {
    IN_FORCE: 'In Force',
    EXPIRED: 'Expired',
    CANCELED: 'Canceled',
    CANCELED_FLAT: 'Canceled Flat',
    SCHEDULED: 'Scheduled'
};

const getCellType = (rowData) => {
    const activePeriod = _.first(rowData.periods);
    const lineOfBusiness = activePeriod.lines.length > 1
        ? activePeriod.lines.map((line) => line) : _.first(activePeriod.lines);
    const icon = activePeriod.lines.length > 1 ? 'mi-unarchive' : LobIconUtil.getMaterialIcon(lineOfBusiness);
    return <Icon icon={icon} title={lineOfBusiness} key={lineOfBusiness} />;
};

const isPolicyActive = (periods) => {
    const currentRenewed = periods.length > 1;
    const activePeriod = _.first(periods);
    return new Date(activePeriod.effective).getTime() < new Date().getTime() && !currentRenewed;
};

const shouldRenewalNotificationShow = (accountPolicyData) => {
    return accountPolicyData.some((policy) => policy.periods.length > 1);
};

const getCellStatus = (rowData) => {
    if (isPolicyActive(rowData.periods)) {
        return <InfoLabel type="success" size="medium" message={messages.statusActive} />;
    }

    return <InfoLabel type="info" size="medium" message={messages.statusRenewed} />;
};

const getCellPolicy = (rowData) => {
    const activePeriod = _.first(rowData.periods);
    const periodWithIDCard = rowData.periods.find((policy) => policy.idCardPublicID);
    return (
        <WMICLink
            className={styles.link}
            to={{
                pathname: `/account-policy-details/${activePeriod.policyId}`,
                productCode: activePeriod.lines[0],
                idCard: periodWithIDCard ? {
                    idCardSessionID: _.get(periodWithIDCard, 'idCardSessionID'),
                    idCardPublicID: _.get(periodWithIDCard, 'idCardPublicID')
                } : null
            }}
        >
            {activePeriod.policyId}
        </WMICLink>
    );
};

const getCellEffective = (rowData, rowIndex) => {
    const activePeriod = _.first(rowData.periods);
    const effectiveDate = new Date(activePeriod.effective);
    const expirationDate = new Date(activePeriod.expiration);
    const periodValue = {
        startDate: {
            year: effectiveDate.getFullYear(),
            month: effectiveDate.getMonth(),
            day: effectiveDate.getDate()
        },
        endDate: {
            year: expirationDate.getFullYear(),
            month: expirationDate.getMonth(),
            day: expirationDate.getDate()
        },
    }
    return (
        <DateRangeField
            id={`effectiveDate_${rowIndex}`}
            value={periodValue}
            hideLabel
            readOnly
        />
    );
};

const getCellClaim = (rowData) => {
    const activePeriod = _.first(rowData.periods);

    const redirectPath = {
        pathname: '/fnol-select-policy',
        state: {
            policyNumber: activePeriod.policyId,
            redirectPath: '/home',
            claimData: { lossDate: new Date() }
        }
    };
    return (
        <WMICLink to={redirectPath} className={styles.link}>
            {messages.fileClaim}
        </WMICLink>
    );
};

const getCellPremium = (rowData, rowIndex) => {
    const activePeriod = _.first(rowData.periods);
    return (
        <CurrencyField
            id={`premium_${rowIndex}`}
            value={activePeriod.premium}
            dataType="object"
            readOnly
            hideLabel
        />
    );
};

const mailToTrigger = (event, email) => {
    event.preventDefault();
    window.location = `mailto:${email}`;
};

const getAccountClaimsWMIC = (anAccountClaimData, state) => {
    if (anAccountClaimData && anAccountClaimData.items) {
        return anAccountClaimData.items.filter((c) => c.claimState === state);
    }
    return [];
};

const isInactivePolicy = (displayStatusWMIC) => {
    return displayStatusWMIC === POLICY_STATUS.CANCELED || displayStatusWMIC === POLICY_STATUS.EXPIRED || displayStatusWMIC === POLICY_STATUS.CANCELED_FLAT;
};

const populateOverduePolicyData = (policyInfo, billingData) => {
    return {
        id: policyInfo.policyId,
        details: {
            overdueAmount: billingData.amountOverDue.amount,
            currency: billingData.amountOverDue.currency,
            effective: policyInfo.effective,
            isInactive: isInactivePolicy(policyInfo.displayStatus_WMIC),
            isPendingCancellation: billingData.isNOITCCompleted,
            policyType: policyInfo.overview,
            billingData: WMICBillingUtil.populateDelinquencyInfo(billingData),
            policyData: policyInfo
        }
    };
};

const loadOverduePoliciesNotShown = () => {
    const savedOverduePoliciesNotShown = JSON.parse(localStorage.getItem('overduePoliciesNotShown'));
    if (savedOverduePoliciesNotShown === null) {
        return true;
    }
    return false;
};

function LandingPage() {
    const modalApi = useModal();
    const savedreviewContactInfoContainerNotShown = JSON.parse(
        localStorage.getItem('reviewContactInfoContainerNotShown')
    );
    const loadReviewContactInfoContainerNotShown = () => {
        if (savedreviewContactInfoContainerNotShown === null) {
            return true;
        }
        return false;
    };

    const savedRCNContainerNotShown = JSON.parse(
        localStorage.getItem('rcnContainerNotShown')
    );
    const loadRCNContainerNotShown = () => {
        if (savedRCNContainerNotShown === null) {
            return true;
        }
        return false;
    };

    const translator = useTranslator();
    const [accountBillingData, setAccountBillingData] = useState([]);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const [accountClaimData, setAccountClaimData] = useState([]);
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const [accountData, setAccountData] = useState([]);
    const [accountContact, setAccountContact] = useState([]);
    const [isLoading, setLoadingState] = useState(false);
    const { capabilitiesConfig, ctaSettings } = appConfig;
    const [hasNotification, setHasNotification] = useState(false);
    const [isListBillAvailable, setIsListBillAvailable] = useState(false);
    const [areClaimsAvailable, setAreClaimsAvailable] = useState(false);
    const [isCtaAvailable, setIsCtaAvailable] = useState(false);
    const [timeTravelOffsetDaysData, setTimeTravelOffsetDaysData] = useState(undefined);
    const [notices, setNotices] = useState([]);
    const [arePeriodicUpdates, setArePeriodicUpdates] = useState(false);
    const [addedPolicies, updateAddedPolicies] = useState([]);
    const [showWeAreSorry, setShowWeAreSorry] = useState(false);
    const [finishedInit, setFinishedInit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showOverduePoliciesModal, setShowOverduePoliciesModal] = useState(false);
    const [distributionType, updateDistributionType] = useState('');
    const [ldFlags, setLdFlags] = useState({});
    const [ctaPeriodicUpdatesFeatureFlag, setCtaPeriodicUpdatesFeatureFlag] = useState({});
    const [isRCNAvailable, setIsRCNAvailable] = useState(false);
    const [hideRcnCta, setHideRcnCta] = useState(undefined);

    // check if Review your contact informaion modal has been interacted with a user
    // and the contact information is confirnmed or changed by the user.
    const [isContactReviewed, setIsContactReviewed] = useState(
        savedreviewContactInfoContainerNotShown !== null &&
            savedreviewContactInfoContainerNotShown === true
    );

    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { search } = useLocation();
    const history = useHistory();
    const CLAIM_STATE = {
        OPEN: 'open',
        CLOSED: 'closed'
    };
    const currentDate = new Date();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();

    const logger = WMICRemoteLogger.getWMICRemoteLogger(
        'LandingPage',
        history.location.pathname,
        window.location,
        ldFlags,
        authHeader
    );

    const hasANotification = (notice) => {
        const tempNotices = notices;
        tempNotices.push(notice);
        setHasNotification(true);
        setNotices(tempNotices);
    };

    const [cookies, setCookie] = useCookies([]);

    const reloadlandingPage = () => {
        history.push('/home');
    };

    const managePaymentStatusModal = () => {
        const queryStrings = new URLSearchParams(search);
        const paramsEncoded = queryStrings.get('params');
        if (paramsEncoded) {
            const params = Buffer.from(paramsEncoded, 'base64').toString('ascii');
            const queryStringsNested = new URLSearchParams(params);
            const paymentStatus = queryStringsNested.get('paymentstatus');
            const invoiceStreamId = queryStringsNested.get('entity');
            if (paymentStatus && invoiceStreamId && (paymentStatus === CONSTANTS.INTEGRATION_CC_PAYMENT_STATUS.COMPLETE || paymentStatus === CONSTANTS.INTEGRATION_CC_PAYMENT_STATUS.ERROR)) {
                modalApi.showModal(<WMICLandingPagePaymentStatusModal
                    paymentStatus={paymentStatus}
                    invoiceStreamId={invoiceStreamId}
                    onClose={reloadlandingPage}
                />);
            }
        }
    };

    const initArePeriodicUpdates = async () => {
        const periodicUpdateParamsDTO_WMIC = {
            UseAddress: true
        };
        const periodicUpdates = await PolicyService.getPeriodicUpdates(periodicUpdateParamsDTO_WMIC, authHeader);
        if (periodicUpdates.items.length > 0) {
            setArePeriodicUpdates(true);
        }
    };

    const fetchBillingData = () => {
        setLoadingState(true);
        let billingPromise = Promise.resolve([]);
        let claimPromise = Promise.resolve([]);
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            billingPromise = AccountBillingDetailsService.getPolicyPaymentSummary_WMIC(authHeader);
        }
        // if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' }) && claimAvailability.isAvailable) {
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' }) && areClaimsAvailable) {
            claimPromise = ClaimService.getClaimSummaries({ claimStates: ['open', 'closed'] }, null, authHeader);
        }
        initArePeriodicUpdates();
        Promise.all([billingPromise.then(setAccountBillingData),
            PolicyService.getAccountPolicySummaries_WMIC(authHeader, authUserData.firstName, authUserData.lastName).then((res) => {
                setAccountPolicyData(res);
            }),
            UsersProfileDetailsService.getAccountsContactSummaries(authHeader).then((res) => {
                setAccountData(res);
            }),
            claimPromise
                .then((res) => {
                    setAccountClaimData(res);
                    if (!_.isEmpty(res)) {
                        publish('CLAIM_TRACKING', {name: 'ClaimEvent', action: 'List Claims', label: `Has Claims (${res.items.length})`});
                    }
                })
        ]).finally(() => {
            setLoadingState(false);
        });
    };

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    const initContact = async () => {
        await WMICAmpUtil.getAccountContact(authHeader,authUserData).then((res) =>{
            if (res) {
                setAccountContact(res.accountContact);
                updateDistributionType(_.get(res, 'prefDistributionMethod_WMIC', ''));

            } else {
                logger.error(new Error(`Contact Not Found for user with grantedAuthorities: ${JSON.stringify(authUserData.grantedAuthorities)}`));
            }
        })

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    const dashboardConsentFeatureFlagAvailability = useCallback(() => {
        return WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.DASHBOARDCONSENT);
    }, [featureFlags, ldFlags]);

    const onSetCookies = useCallback(
        (params) => {
            setCookie(params);
        }, [setCookie]
    );

    useEffect(() => {
        const ampAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.AMP);
        const ampNotice = WMICFeatureFlagUtil.getAMPAvailabilityInfo(ampAvailability, currentDate, translator);
        const claimAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.CLAIMS);
        const claimNotice = WMICFeatureFlagUtil.getClaimsAvailabilityInfo(claimAvailability, currentDate, translator);
        const bankAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.BANK);
        const bankNotice = WMICFeatureFlagUtil.getBankAvailabilityInfo(bankAvailability, currentDate, translator);
        const paymentAvailability = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.PAYMENTS);
        const paymentNotice = WMICFeatureFlagUtil.getPaymentsAvailabilityInfo(paymentAvailability, currentDate, translator);
        const listBillAvailable = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.CTA_LISTBILL);
        const ctaAvailable = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.CTA);
        const timeTravelOffsetDays = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.TEST_DATA);
        const ctaPeriodicUpdates = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.CTA_PERIODIC_UPDATES);
        const ctaRCN = WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.CTA_RCN);

        if(Object.entries(ldFlags).length > 1 && ampAvailability && ampAvailability.isAvailable === false) {
            setShowWeAreSorry(true);
        }
        setIsListBillAvailable(listBillAvailable?.isAvailable);
        setAreClaimsAvailable(claimAvailability?.isAvailable);
        setIsCtaAvailable(ctaAvailable?.isAvailable);
        setIsRCNAvailable(ctaRCN?.isAvailable);
        setTimeTravelOffsetDaysData(timeTravelOffsetDays);
        setCtaPeriodicUpdatesFeatureFlag(ctaPeriodicUpdates?.isAvailable);
        if (ampNotice && ampNotice.body) {
            hasANotification(ampNotice);
        }
        if (claimNotice && claimNotice.body) {
            hasANotification(claimNotice);
        }
        if (bankNotice && bankNotice.body) {
            hasANotification(bankNotice);
        }
        if (paymentNotice && paymentNotice.body) {
            hasANotification(paymentNotice);
        }
    }, [ldFlags]);

    useEffect(() => {
        if (showWeAreSorry) {
            history.push('technicalErrorPage/maintenance');
        }
    }, [showWeAreSorry]);

    useEffect(() => {
        initFeatureFlags();
        initContact();
        fetchBillingData();
        managePaymentStatusModal();
        setFinishedInit(true);
        // disable re render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (finishedInit && !WMICTooltipCookieUtil.hasShowConsentCookie(authUserData, cookies)
            && dashboardConsentFeatureFlagAvailability().isAvailable) {
            Promise.all([
                AccountBillingDetailsService.getPolicyPaymentSummary_WMIC(authHeader).then(setAccountBillingData),
                PolicyService.getAccountPolicySummaries_WMIC(authHeader, authUserData.firstName, authUserData.lastName).then(setAccountPolicyData)
            ]).then(() => {
                PolicyService.isEdocsReconsentRequired(authHeader).then((consentRequired) => {
                    if (consentRequired) {
                        WMICTooltipCookieUtil.setShowConsentCookie(authUserData, onSetCookies);
                        const email = accountContact.emailAddress1
                        modalApi.showModal(
                            <WMICEditContactModal
                                email={email}
                                updateRefresh={setRefresh}
                                refresh={refresh}
                                distributionType={distributionType}
                                updateDistributionType={updateDistributionType}
                            />
                        );
                    }
                });
            });
        };
    }, [finishedInit, ldFlags, accountContact]);

    useEffect(() => {
        if (!_.isEmpty(addedPolicies)) {
            fetchBillingData();
            updateAddedPolicies([]);
        }
    }, [addedPolicies]);

    // force re-fetch on feature flag update - look to refactor flags in future.
    useEffect(() => {
        fetchBillingData();
    }, [areClaimsAvailable])

    const aggregateBilling = useCallback((invoices) => {
        if (invoices.length === 0) {
            return null;
        }

        let total = 0;
        let due = new Date(invoices[0].dueDate);

        _.each(invoices, (invoice) => {
            total += invoice.amountDue.amount;
            const newDue = new Date(invoice.dueDate);
            if (newDue.getTime() < due.getTime()) {
                due = newDue;
            }
        });

        return {
            amountDue: {
                amount: total,
                currency: invoices[0].amountDue.currency
            },
            earliestDue: due
        };
    }, []);

    const getMyBalanceOverDueAndCurrentData = useCallback(
        (invoiceStatus) => {
            if (accountBillingData && accountBillingData.activeInvoices) {
                const invoices = accountBillingData.activeInvoices;
                return aggregateBilling(
                    invoices.filter((invoice) => invoice.invoiceStatus === invoiceStatus)
                );
            }
            return null;
        },
        [accountBillingData, aggregateBilling]
    );

    const getBalanceData = useCallback(() => {
        const overdue = getMyBalanceOverDueAndCurrentData('due');
        const current = getMyBalanceOverDueAndCurrentData('billed');
        if (overdue && current) {
            return {
                amount: current.amountDue.amount + overdue.amountDue.amount,
                currency: current.amountDue.currency
            };
        }
        if (overdue || current) {
            return (overdue || current).amountDue;
        }
        return null;
    }, [getMyBalanceOverDueAndCurrentData]);

    const readValue = useCallback(
        (id, path) => {
            if (path === 'balanceAmount') {
                return getBalanceData();
            }
            if (path === 'overDueAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'amountDue');
            }
            if (path === 'currentAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('billed'), 'amountDue');
            }
            if (path === 'dueDate') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'earliestDue');
            }
            return _.get(accountBillingData, path);
        },
        [accountBillingData, getBalanceData, getMyBalanceOverDueAndCurrentData]
    );

    const policyWithIDCard = useMemo(() => {
        let periodWithIDCard;
        accountPolicyData.some((policyData) => {
            return policyData.periods.some((period) => {
                if (period.idCardPublicID) {
                    periodWithIDCard = period;
                    return true;
                }
                return false;
            });
        });
        return periodWithIDCard;
    }, [accountPolicyData]);

    const getIDCardQuickLink = useMemo(() => {
        if (!policyWithIDCard) {
            return null;
        }
        return DocumentService.downloadPolicyDocument(
            policyWithIDCard.idCardPublicID,
            policyWithIDCard.idCardSessionID
        );
    }, [policyWithIDCard]);

    const automatedPaymentURL = useMemo(() => {
        const billingID = _.get(accountBillingData, 'manualPayPaymentGroups[0]');

        if (!billingID) {
            return undefined;
        }
        return `billing-summary/${billingID}`;
    }, [accountBillingData]);

    const firstAccountSummaryDefined = useMemo(() => {
        return accountData.length <= 1 && !_.isEmpty(accountData);
    }, [accountData]);

    const claimCapabilityCheck = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'fnol' });
    }, [capabilitiesConfig]);

    const getCellAgent = useCallback(
        (rowData) => {
            return (
                <>
                    <WMICLink className={styles.link} onClick={(e) => mailToTrigger(e, rowData.producerContact.email)} href="/">
                        {rowData.producerContact.displayName}
                    </WMICLink>
                    <br />
                    {rowData.producerContact.phoneNumber ? (
                        <span>
                            {translator(messages.policyDetailsTel)}
                            {rowData.producerContact.phoneNumber}
                        </span>
                    ) : (
                        ''
                    )}
                </>
            );
        },
        [translator]
    );

    const getTableOverrides = useCallback(() => {
        return {
            typeColumn: { renderCell: getCellType },
            statusColumn: { renderCell: getCellStatus },
            policyColumn: { renderCell: getCellPolicy },
            effectiveColumn: { renderCell: getCellEffective },
            premiumColumn: { renderCell: getCellPremium },
            fileAClaimColumn: {
                visible: claimCapabilityCheck,
                renderCell: getCellClaim
            },
            agentColumn: {
                visible: accountData.length > 1,
                renderCell: getCellAgent
            }
        };
    }, [accountData, getCellAgent, claimCapabilityCheck]);

    const onFileAClaimQuickLinkClick = useCallback(
        () => () => {
            return history.push({
                pathname: '/fnol-select-policy',
                state: {
                    redirectPath: '/home',
                    claimData: { lossDate: new Date() }
                }
            });
        },
        [history]
    );

    const onAddPolicyReminderTooltipClose = useCallback(
        (event) => {
            event.preventDefault();
            WMICTooltipCookieUtil.setAddPolicyReminderCookie(authUserData, onSetCookies);
        }, [authUserData, onSetCookies]
    );

    const showBillingSection = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' });
    }, [capabilitiesConfig]);

    const paymentModal = (policyModalData) => {
        modalApi.showModal(<WMICPaymentModal fetchBillingData={fetchBillingData} data={policyModalData} isOpen isOpenFromOverdueContainer={true} ldFlags={ldFlags} />).catch(_.noop);
    };

    const addPolicyModal = () => {
        modalApi.showModal(<WMICAddPolicyModal data={{}} isOpen updateAddedPolicies={updateAddedPolicies} policies={{...accountPolicyData, ...accountBillingData}} />).catch(_.noop);
    };

    const closePaymentModal = () => {
        localStorage.setItem('overduePoliciesNotShown', JSON.stringify(false));
    };

    const closeContactInfoModal = () => {
        if(loadOverduePoliciesNotShown && isCtaAvailable) {
            setShowOverduePoliciesModal(true);
        }
    };

    const getPolicyDataForClaim = useCallback((policyNumber) => {
        const policy = accountPolicyData.find((accountPolicy) => policyNumber === accountPolicy.periods[0].policyId)
        return _.get(policy, 'periods[0].overview')
    }, [accountPolicyData])

    const getTooltipAndButtonWMIC = () => {
        return (
                <Button
                    aria-haspopup="true"
                    type="secondary"
                    className="ww-btn-secondary"
                    trackButtonIdentifier={translator(messages.addPolicyTrackButtonIdentifier)}
                    onClick={(event) => {
                        onAddPolicyReminderTooltipClose(event);
                        addPolicyModal();
                    }}
                >
                    {translator(messages.addPolicy)}
                </Button>
        );
    };

    const getNotices = () => {
        return (
            <WMICNoticesComponent notices={notices} />
        );
    };

    const showCallToAction = (billingData, period) => {
        const hasOverdueAmount = (data) => {
            const overdue = new Decimal(data.amountOverDue ? data.amountOverDue.amount : 0);
            const priorTermBalance = new Decimal(data.priorTermBalance ? data.priorTermBalance.amount : 0);
            const totalOverdue = overdue.plus(priorTermBalance);
            return totalOverdue && new Decimal(totalOverdue).isPositive();
        };

        let renewalStatus;

        if (period.renewal_Details_WMIC && period.renewal_Details_WMIC.displayStatus_WMIC) {
            renewalStatus = period.renewal_Details_WMIC.displayStatus_WMIC;
        }

        let canShowPayNow = WMICPaymentUtil.canShowPayNowButton(billingData, WMICPaymentUtil.getPolicyStatusName(period.displayStatus_WMIC, renewalStatus));

        if (WMICPaymentUtil.isListBill(billingData)) {
            if (isListBillAvailable) {
                if (billingData.delinquencyInfo.hasOpenDelinquency) {
                    const currentTime = new Date();
                    // offset the currentTime so that we can test time travel scenarios
                    const timeTravelOffsetDays = timeTravelOffsetDaysData?.isAvailable ? timeTravelOffsetDaysData?.timeTravelOffsetDays : 0;
                    currentTime.setDate(currentTime.getDate() + timeTravelOffsetDays);

                    const startDateCTA = new Date(billingData.delinquencyInfo.startDate);
                    startDateCTA.setDate(startDateCTA.getDate() + ctaSettings.delayListBillDelinquencyDays);
                    canShowPayNow = currentTime > startDateCTA;
                } else {
                    canShowPayNow = billingData.isPolicyDelinquent;
                }
            } else {
                canShowPayNow = false;
            }
        }

        return canShowPayNow && hasOverdueAmount(billingData);
    };

    /* Modal version when maintenance */
    const getShowWeAreSorry = useCallback(() => {
        if(showWeAreSorry) {
            const body = WMICRichTextUtil.translateRichText(translator(messages.documentErrorMessage));
            const title = translator(messages.wereSorry);
            modalApi.showModal(
                <WMICModal
                    id="insuranceIDCardModal"
                    modalHeader={title}
                    modalBody={body}
                    onConfirmCallback={() => _.noop()}
                    confirmButtonText={translator(messages.closeButtonText)}
                />
            );
        }
    }, [showWeAreSorry]);

    const getAccountPoliciesAggregatedDataWMIC = (anAccountPolicyData, anAccountBillingData) => {
        const policiesAggregatedData = { activePolicies: [], inactivePolicies: [], overduePolicies: [] };
        if (anAccountPolicyData && anAccountBillingData) {
            anAccountPolicyData.forEach((policy) => {
                if (policy.periods.length > 0) {
                    const activePeriod = policy.periods[0];
                    if (activePeriod) {
                        const billingInfo = anAccountBillingData.find((element) => element.policyId === activePeriod.policyId);
                        if (activePeriod && billingInfo) {
                            if (!isInactivePolicy(activePeriod.displayStatus_WMIC)) {
                                policiesAggregatedData.activePolicies.push({ policyData: activePeriod, billingData: billingInfo });
                            } else {
                                policiesAggregatedData.inactivePolicies.push({ policyData: activePeriod, billingData: billingInfo });
                            }
                            if (showCallToAction(billingInfo, activePeriod)) {
                                policiesAggregatedData.overduePolicies.push(populateOverduePolicyData(activePeriod, billingInfo));
                            }
                        }
                    }
                }
            });
        }
        return policiesAggregatedData;
    };

    const policies = useMemo(() => {
        return getAccountPoliciesAggregatedDataWMIC(accountPolicyData, accountBillingData);
    }, [accountPolicyData, accountBillingData]);

    const iterableOverrides = useMemo(() => {
        const claimOverrides = _.get(accountClaimData, 'items', []).map((item, index) => {
            return {
                [`claimSummaryBox${index}`]: {
                    title: getPolicyDataForClaim(item.policyNumber)
                },
                [`closedClaimSummaryBox${index}`]: {
                    title: getPolicyDataForClaim(item.policyNumber)
                }
            };
        });

        return Object.assign({}, ...claimOverrides);
    }, [accountClaimData, getPolicyDataForClaim]);


    const policyNumberWithRCN = (policyData) => {
        let policyPeriodNumber;
        const rcnDocumentCode = "RCN_05_23";

        if(policyData) {
            policyData.forEach((policy) => {
                if (policy.periods.length > 0) {
                    policy.periods.forEach((period) => {
                        if(period.documents_WMIC.indexOf(rcnDocumentCode) !== -1 ){
                            policyPeriodNumber = period.policyId;
                        }
                    });
                }
            });
        }

        return policyPeriodNumber;
    }

    useEffect(() => {
        const policyNumber = policyNumberWithRCN(accountPolicyData);
        if(policyNumber){
            const hideRcnCtaVar = WMICTooltipCookieUtil.hasDontShowAgainCookie(authUserData, cookies, policyNumber);
            setHideRcnCta(hideRcnCtaVar);
        }

    }, [accountPolicyData]);

    const policyTermNumberWithRCN = (policyData) => {
        let policyTermNumber;
        const rcnDocumentCode = "RCN_05_23";

        if(policyData) {
            policyData.forEach((policy) => {
                if (policy.periods.length > 0) {
                    policy.periods.forEach((period) => {
                        if(period.documents_WMIC.indexOf(rcnDocumentCode) !== -1 ){
                            policyTermNumber = period.termNumber_WMIC;
                        }
                    });
                }
            });
        }

        return policyTermNumber;
    }

    const getBillingDataForTerm = (billingData, policyNumber, termNumber) => {
        let billingDataToReturn;

        billingData.forEach((policyBillingData) => {
            if (policyBillingData.policyId === policyNumber) {
                if (policyBillingData.termNumber === termNumber) {
                    billingDataToReturn = policyBillingData;
                } else if (policyBillingData.renewalPaymentDetails?.termNumber === termNumber) {
                    billingDataToReturn = policyBillingData.renewalPaymentDetails;
                }
            }
        });

        return billingDataToReturn;
    }

    const showRcn = (policyData, billingData) => {
        let showRcnContainer = false;
        const rcnDocumentCode = "RCN_05_23";

        if (!_.isEmpty(policyData) && !_.isEmpty(billingData)) {
            policyData.forEach((policy) => {
                policy.periods.forEach((period) => {
                    const hasRCN = period.documents_WMIC.indexOf(rcnDocumentCode) !== -1;
                    const term = period.termNumber_WMIC;
                    const policyNumber = period.policyId;

                    if (hasRCN) {
                        const billingDataForTerm = getBillingDataForTerm(billingData, policyNumber, term);

                        // offset the currentDate so that we can test time travel scenarios
                        const currentDate = new Date();
                        const timeTravelOffsetDays = timeTravelOffsetDaysData?.isAvailable ? timeTravelOffsetDaysData?.timeTravelOffsetDays : 0;
                        currentDate.setDate(currentDate.getDate() + timeTravelOffsetDays);
                        
                        //set currentDate time to midnight to compare with policy term effective date time
                        currentDate.setHours(0);
                        currentDate.setMinutes(0);
                        currentDate.setSeconds(0);
    
                        const policyTermEffectiveDate = new Date(period.effective);
                        const notificationExpiryDays = 30;
                        policyTermEffectiveDate.setDate(policyTermEffectiveDate.getDate() + notificationExpiryDays);

                        // check if any payment was made
                        if(billingDataForTerm && currentDate <= policyTermEffectiveDate) {
                            if ((billingDataForTerm.paymentPlan === "Full Pay" || billingDataForTerm.paymentPlan === "Two Pay") && billingDataForTerm.totalPremiumAmount.amount === billingDataForTerm.outstandingAmount.amount) {
                                showRcnContainer = true;
                            } else if (billingDataForTerm.paymentPlan === "Monthly Automated Recurring") {
                                showRcnContainer = true;
                            }
                        }
                    }
                })
            })
        }

        return showRcnContainer;
    }

    const showRcnMemo = useMemo(() => {
        return showRcn(accountPolicyData, accountBillingData);
    }, [accountPolicyData, accountBillingData]);

    const override = {
        ...iterableOverrides,
        '@field': {
            phone: {
                labelPosition: 'left'
            },
            fetchBillingData: fetchBillingData,
        },
        notificationContainer: {
            content: getNotices(),
            visible: hasNotification
        },
        wmicLoader: {
            visible: isLoading
        },
        pageHome: {
            visible: !isLoading
        },
        payNowButton: {
            visible: firstAccountSummaryDefined
        },
        mediaSidebar: {
            showContact: accountData.length > 1
        },
        policiesTable: {
            data: accountPolicyData
        },
        printAnIDCardLink: {
            visible: !_.isNil(policyWithIDCard),
            href: getIDCardQuickLink,
            target: '_blank'
        },
        setupAutomatedPaymentsLink: {
            to: automatedPaymentURL,
            visible: !!automatedPaymentURL && showBillingSection
        },
        renewalNotification: {
            visible: shouldRenewalNotificationShow(accountPolicyData)
        },
        fileAClaimLink: {
            onClick: onFileAClaimQuickLinkClick(),
            visible: claimCapabilityCheck
        },
        tileGroupContainer: {
            visible: showBillingSection
        },
        makeAPaymentLink: {
            visible: showBillingSection
        },
        tooltipAndButtonDiv: {
            content: getTooltipAndButtonWMIC()
        },
        overduePoliciesContainer: {
            visible: isCtaAvailable,
            openModalOnRender: loadOverduePoliciesNotShown(),
            openModal: showOverduePoliciesModal,
            policies: policies.overduePolicies,
            onPayPolicyClick: paymentModal,
            onClose: closePaymentModal
        },
        reviewContactInfoContainer: {
            openModalOnRender: loadReviewContactInfoContainerNotShown(),
            visible: isCtaAvailable && !isLoading && arePeriodicUpdates && ctaPeriodicUpdatesFeatureFlag && !isContactReviewed,
            onClose: closeContactInfoModal,
            updateIsContactReviewed: setIsContactReviewed,
        },
        rcnContainer: {
            visible: isRCNAvailable && !hideRcnCta && showRcnMemo,
            openModalOnRender: loadRCNContainerNotShown(),
            openModal: showRcnMemo,
            policyPeriodNumber: policyNumberWithRCN(accountPolicyData),
            termNumber: policyTermNumberWithRCN(accountPolicyData),
            hideRcnCta: setHideRcnCta
        },
        activePoliciesIterable: {
            data: policies.activePolicies,
            visible: policies.activePolicies.length > 0,
        },
        inactivePoliciesIterable: {
            data: policies.inactivePolicies,
            visible:policies.inactivePolicies.length > 0,
        },
        openClaimsTitle: {
            visible: areClaimsAvailable && getAccountClaimsWMIC(accountClaimData, CLAIM_STATE.OPEN).length > 0
        },
        openClaimsIterable: {
            data: getAccountClaimsWMIC(accountClaimData, CLAIM_STATE.OPEN),
            visible: areClaimsAvailable && getAccountClaimsWMIC(accountClaimData, CLAIM_STATE.OPEN).length > 0
        },
        closedClaimsTitle: {
            visible: areClaimsAvailable && getAccountClaimsWMIC(accountClaimData, CLAIM_STATE.CLOSED).length > 0
        },
        closedClaimsIterable: {
            data: getAccountClaimsWMIC(accountClaimData, CLAIM_STATE.CLOSED),
            visible: areClaimsAvailable && getAccountClaimsWMIC(accountClaimData, CLAIM_STATE.CLOSED).length > 0
        },
        activePoliciesTitle: {
            content: translator(messages.activePolicies),
            visible:policies.activePolicies.length > 0
        },
        inactivePoliciesTitle: {
            content: translator(messages.inactivePolicies),
            visible: policies.inactivePolicies.length > 0
        },
        pageTitle: {
            content: translator(messages.homeTitle)
        },
        bannerforActivePoliciesContainer: {
            visible:policies.activePolicies.length > 0
        },
        apbannerTitle: {
            content: WMICRichTextUtil.translateRichText(translator(messages.apBannerTitleTxt))
        },
        ...getTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveComponentMap: {
            wmicOverduePolicies: WMICOverduePolicies,
            wmicRcn: WMICRcn,
            policySummaryBoxComponent: WMICPolicySummaryBoxAMP,
            claimSummaryBoxComponent: WMICClaimSummaryBoxAMP,
            wmicReviewContactInfo: WMICReviewContactInfo,
            WMICLoaderComponent: WMICLoader,
            WMICNoticesComponent: WMICNoticesComponent
        }
    };

    return <MetadataContent uiProps={metadata.pageContent} overrideProps={override} {...resolvers} />;
}

export default withRouter(LandingPage);
