import { defineMessages } from 'react-intl';

export default defineMessages({
    accountSummary: {
        id: 'portal.views.home-page-layout.Account Summary',
        defaultMessage: 'Account Summary',
    },
    myBalance: {
        id: 'portal.directives.templates.billing-summary-tiles.My Balance',
        defaultMessage: 'My Balance',
    },
    overdue: {
        id: 'portal.directives.templates.billing-summary-tiles.Overdue',
        defaultMessage: 'Overdue',
    },
    payNow: {
        id: 'portal.directives.templates.billing-summary-tiles.Pay Now',
        defaultMessage: 'Pay Now',
    },
    myNextBill: {
        id: 'portal.directives.templates.billing-summary-tiles.My Next Bill',
        defaultMessage: 'My Next Bill'
    },
    due: {
        id: 'portal.directives.templates.billing-summary-tiles.Due',
        defaultMessage: 'Due',
    },
    updateMyDetails: {
        id: 'portal.views.home-page-layout.Update My Details',
        defaultMessage: 'Update My Details'
    },
    lastPayment: {
        id: 'portal.directives.templates.billing-summary-tiles.My Last Payment',
        defaultMessage: 'My Last Payment',
    },
    paid: {
        id: 'portal.directives.templates.billing-summary-tiles.Paid',
        defaultMessage: 'Paid',
    },
    renewalInfo: {
        id: 'portal.views.home-page-layout.You have policies due for renewal.',
        defaultMessage: 'You have policies due for renewal.'
    },
    policies: {
        id: 'portal.views.home-page-layout.Policies',
        defaultMessage: 'Policies'
    },
    type: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.type',
        defaultMessage: 'Type'
    },
    statusActive: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.Active',
        defaultMessage: 'Active'
    },
    statusRenewed: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.Renewed',
        defaultMessage: 'Renewed'
    },
    status: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.status',
        defaultMessage: 'Status'
    },
    policy: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.policy',
        defaultMessage: 'Policy'
    },
    effective: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.effective',
        defaultMessage: 'Effective Period'
    },
    premium: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.premium',
        defaultMessage: 'Premium'
    },
    myQuickLinks: {
        id: 'portal.views.home-page-layout.My Quick Links',
        defaultMessage: 'My Quick Links'
    },
    printanIDCard: {
        id: 'portal.views.home-page-layout.Print an ID Card',
        defaultMessage: 'Print an ID Card'
    },
    makeAPayment: {
        id: 'portal.views.home-page-layout.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    setupAutomaticPayments: {
        id: 'portal.views.home-page-layout.Setup Automatic Payments',
        defaultMessage: 'Setup Automatic Payments'
    },
    changeMyPolicy: {
        id: 'portal.views.home-page-layout.ChangeMyPolicy',
        defaultMessage: 'Change My Policy'
    },
    fileClaim: {
        id: 'portal.views.home-page-layout.FileClaim',
        defaultMessage: 'File a Claim'
    },
    getAPersonalAutoQuote: {
        id: 'portal.views.home-page-layout.Get a Personal Auto Quote',
        defaultMessage: 'Get a Personal Auto Quote'
    },
    getAHomeOwnersQuote: {
        id: 'portal.views.home-page-layout.Get a Home Owners Quote',
        defaultMessage: 'Get a Home Owners Quote'
    },
    policyDetailsAgent: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.agent',
        defaultMessage: 'Agent'
    },
    policyDetailsTel: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.tel',
        defaultMessage: 'T.'
    },
    policyDetailsIdCard: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.id card',
        defaultMessage: 'ID Card'
    },
    policyDetailsFileClaim: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.fileClaim',
        defaultMessage: 'File a Claim'
    },
    myBalanceTileCurrent: {
        id: 'portal.directives.templates.billing-summary-tiles.Current',
        defaultMessage: 'Current'
    },
    policyNotShowing: {
        id: 'portal.views.home-page-layout.Do you have a policy with us but it is not showing?',
        defaultMessage: 'Do you have a policy with us but it is not showing?'
    },
    addPolicy: {
        id: 'portal.views.home-page-layout.Add Policy',
        defaultMessage: 'Add a Policy'
    },
    addPolicyTrackButtonIdentifier: {
        id: 'portal.views.home-page-layout.tracking.Add Policy',
        defaultMessage: 'Home Page - Link a Policy'
    },
    openClaimsTitle: {
        id: 'portal.views.home-page-layout.OpenClaims',
        defaultMessage: 'OPEN CLAIMS',
    },
    closedClaimsTitle: {
        id: 'portal.views.home-page-layout.ClosedClaims',
        defaultMessage: 'COMPLETED CLAIMS',
    },
    tooltipText: {
        id: 'portal.views.home-page-layout.Tootip Text',
        defaultMessage: 'Missing a policy on your account? You can add existing policies to your account using the "Add a Policy" feature.'
    },
    tooltipTextAnnouncing: {
        id: 'portal.views.home-page-layout.Tootip Text Announcing',
        defaultMessage: 'Add a policy open tooltip.'
    },
    tooltipCloseText: {
        id: 'portal.views.home-page-layout.Tootip Close Text',
        defaultMessage: 'Close tooltip and don\'t show again'
    },
    activePolicies: {
        id: 'portal.views.home-page-layout.ACTIVE POLICIES',
        defaultMessage: 'ACTIVE POLICIES'
    },
    inactivePolicies: {
        id: 'portal.views.home-page-layout.INACTIVE POLICIES',
        defaultMessage: 'INACTIVE POLICIES'
    },
    homeTitle: {
        id: 'portal.views.home-page-layout.Home',
        defaultMessage: 'Home'
    },
    apBannerTitleTxt: {
        id: 'portal.views.home-page-layout.Get a policy review by calling 800-640-2920',
        defaultMessage: 'Get a policy review by calling &lt;a href="tel:800-640-2920"&gt;800-640-2920&lt;/a&gt;'
    },
    coverageInfroTxt: {
        id: 'portal.views.home-page-layout.We\'ll review your coverages, discounts, and more to ensure your policy fits your needs and your budget.',
        defaultMessage: 'portal.views.home-page-layout.We\'ll review your coverages, discounts, and more to ensure your policy fits your needs and your budget.'
    }
});
